._loading_overlay_overlay {
  height: 100vh !important;
  width: 100vw !important;
}

._loading_overlay_wrapper {
  display: none;
}

._loading_overlay_overlay,
._loading_overlay_wrapper {
  position: fixed !important;
  left: 0;
  top: 0;
  z-index: 99999 !important;
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
    padding: 0.3rem 1.5rem;
    white-space: nowrap;
  }
}
