.adaptarMargemEsquerda {
  margin-left: 20px;
}
.adaptarNavLinkBlue {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0fa4c3;
  font-size: 20px;
}
.adaptarNavLinkPink {
  /* width: 100px; */
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ec97a8;
  font-size: 20px;
}
.aLink {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 991px) and (max-width: 1302px) {
  .adaptartamanhoNavItem {
    /* font-size: 1vw; */
    /* padding: 12px; */
    padding: 0;
  }
  .adaptartamanhoNavLink {
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    padding: 0px;
  }
  .adaptartamanhoSpan {
    font-size: 1.1vw;
    display: block;
    /* width: 100% !important; */
  }
  .adaptartamanhoButtonNavLink {
    padding: 6px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    justify-content: center;
    align-items: center;
  }
  .adaptarMargemEsquerda {
    margin-left: 8px;
  }
  .adaptarNavLinkBlue {
    width: 32px;
    height: 32px;
    font-size: 1.4vw;
  }
  .adaptarNavLinkPink {
    height: 32px;
    font-size: 1.1vw;
  }
  .navItemButton {
    padding: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
