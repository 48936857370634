table [data-backdrop='backdrop'] {
  width: 100%;
}

#modal-notes {
  left: 0;
  margin: auto;
  max-width: 80vw;
  right: 0;
}
